import Arrow from '@sats-group/icons/18/arrow-down';
import cn from 'classnames';
import React from 'react';

import useToggle from 'root/client/hooks/use-toggle';

import Collapse from '../collapse/collapse';
import Price from '../price/price';
import RadioButton from '../radio-button/radio-button';
import SellingPoints from '../selling-points/selling-points';
import Text from '../text/text';

import { ChoiceBoxExpandable as Props } from './choice-box-expandable.types';

const ChoiceBoxExpandable: React.FunctionComponent<Props> = ({
  description,
  detailsLabel,
  onClick = () => {},
  open = false,
  price,
  radio,
  selected,
  sellingPoints,
  tagline,
  title,
}) => {
  const [isOpen, toggleOpen] = useToggle(open);

  return (
    <div
      className={cn('choice-box-expandable', {
        'choice-box-expandable--selected': selected,
      })}
    >
      {tagline ? (
        <div
          className={cn('choice-box-expandable__tagline', {
            'choice-box-expandable__tagline--selected': selected,
          })}
        >
          <Text variant={Text.variants.contrast} size={Text.sizes.header3}>
            {tagline}
          </Text>
        </div>
      ) : null}
      <div className="choice-box-expandable__content" onClick={() => onClick()}>
        <div>
          <div className="choice-box-expandable__header">
            <Text
              className={cn('choice-box-expandable__title', {
                'choice-box-expandable__title--selected': selected,
              })}
              variant={Text.variants.contrast}
              size={Text.sizes.header2}
            >
              {title}
            </Text>
            <RadioButton
              {...radio}
              visibleLabel={false}
              defaultChecked={selected}
            />
          </div>
          <Text>{description}</Text>
        </div>
        <div className="choice-box-expandable__expander">
          <Collapse isOpen={isOpen}>
            <SellingPoints {...sellingPoints} />
          </Collapse>
        </div>
        {price ? (
          <div className="choice-box-expandable__price">
            <Price
              {...price}
              theme={selected ? Price.themes.featured : undefined}
            />
          </div>
        ) : null}
      </div>
      <div
        className={cn('choice-box-expandable__trigger', {
          'choice-box-expandable__trigger--selected': selected,
        })}
        onClick={toggleOpen}
      >
        <Text>{detailsLabel}</Text>
        <div
          className={cn('choice-box-expandable__arrow', {
            ['choice-box-expandable__arrow--close']: isOpen,
          })}
        >
          <Arrow />
        </div>
      </div>
    </div>
  );
};

export default ChoiceBoxExpandable;
