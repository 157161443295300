import React from 'react';

import ClubLink from 'root/client/components/club-link/club-link';

import type { ClubLinkList as Props } from './alternate-checkout-select-club.types';

const ClubLinkList: React.FC<Props> = ({ clubs }) => (
  <div className="alternate-checkout-select-club__club-link-list">
    {clubs.map(club => (
      <ClubLink {...club} />
    ))}
  </div>
);

export default ClubLinkList;
