import React from 'react';

import ContactUs from 'root/client/components/contact-us/contact-us';
import ContentContainer from 'root/client/components/content-container/content-container';
import Expander from 'root/client/components/expander/expander';
import Layout from 'root/client/components/layout/layout';
import Message from 'root/client/components/message/message';
import PageHeader from 'root/client/components/page-header/page-header';
import RichText from 'root/client/components/rich-text/rich-text';
import SplitInfoModule from 'root/client/components/split-info-module/split-info-module';
import Text from 'root/client/components/text/text';
import TextLinkCard from 'root/client/components/text-link-card/text-link-card';

import { CustomerService as Props } from './customer-service.types';

const CustomerService: React.FunctionComponent<Props> = ({
  categories,
  categoriesDescription,
  categoriesTitle,
  contact,
  faq,
  header,
  highlight,
  layout,
  message,
}) => (
  <Layout {...layout} theme={Layout.themes.primary}>
    <div className="customer-service">
      <PageHeader {...header} contentWidth={ContentContainer.widths.medium} />
      <div className="customer-service__body">
        {message ? (
          <div>
            <ContentContainer width={ContentContainer.widths.medium}>
              <Message {...message} type={Message.types.info} />
            </ContentContainer>
          </div>
        ) : null}
        <div>
          <ContentContainer width={ContentContainer.widths.medium}>
            <div className="customer-service__categories-title">
              <Text size={Text.sizes.header2}>{categoriesTitle}</Text>
            </div>
            <div className="customer-service__categories-description">
              <Text size={Text.sizes.basic} theme={Text.themes.medium}>
                {categoriesDescription}
              </Text>
            </div>
            <ul className="customer-service__categories">
              {categories.map(entry => (
                <li key={entry.href}>
                  <TextLinkCard {...entry} />
                </li>
              ))}
            </ul>
          </ContentContainer>
        </div>
        <div>
          <ContentContainer width={ContentContainer.widths.medium}>
            <Expander {...faq} itemRenderer={RichText} />
          </ContentContainer>
        </div>
        {highlight ? (
          <div>
            <ContentContainer width={ContentContainer.widths.medium}>
              <SplitInfoModule {...highlight} />
            </ContentContainer>
          </div>
        ) : null}
        {contact ? (
          <div>
            <ContentContainer width={ContentContainer.widths.medium}>
              <ContactUs {...contact} />
            </ContentContainer>
          </div>
        ) : null}
      </div>
    </div>
  </Layout>
);

export default CustomerService;
