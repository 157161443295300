import Discount32 from '@sats-group/icons/32/discount';
import React from 'react';
import StarSticker32 from '@sats-group/icons/32/star-sticker';

import {
  type LayeredIcon as LayeredIconType,
  colors,
} from 'root/client/components/layered-icon/layered-icon.types';
import LayeredIcon from 'root/client/components/layered-icon/layered-icon';

import Text from '../text/text';

const Discount: React.FC<{ text: string }> = ({ text }) => {
  const icons: LayeredIconType['icons'] = [
    { icon: <StarSticker32 />, color: colors.dark },
    { icon: <Discount32 />, color: colors.light },
  ];
  return (
    <div className="discount">
      <div className="discount__content">
        <div className="discount__icon">
          <LayeredIcon icons={icons} />
        </div>
        <div className="discount__text">
          <Text>{text}</Text>
        </div>
      </div>
    </div>
  );
};

export default Discount;
