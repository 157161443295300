import React, { useState } from 'react';

import ChoiceBox from 'root/client/components/choice-box/choice-box';
import ChoiceBoxExpandable from 'root/client/components/choice-box-expandable/choice-box-expandable';
import SalesLayout from 'root/client/components/sales-layout/sales-layout';

import { AlternateCheckoutMembership as Props } from './alternate-checkout-membership.types';

const AlternateCheckoutMembership: React.FC<Props> = ({
  layout,
  payments,
  types,
}) => {
  console.log(payments);
  const [selectedMembershipType, setSelectedMembershipType] =
    useState<string>();

  return (
    <SalesLayout {...layout}>
      <div className="alternate-checkout-membership">
        <div className="alternate-checkout-membership__types">
          {types?.map(type => (
            <ChoiceBoxExpandable
              key={type.radio.value}
              {...type}
              selected={selectedMembershipType === type.radio.value}
              onClick={() => {
                setSelectedMembershipType(type.radio.value);
              }}
            />
          ))}

          {payments.map(payment => (
            <ChoiceBox {...payment} />
          ))}
        </div>
      </div>
    </SalesLayout>
  );
};

export default AlternateCheckoutMembership;
