import ArrowDown from '@sats-group/icons/18/arrow-down';
import Booked from '@sats-group/icons/18/booked';
import cn from 'classnames';
import React from 'react';

import useToggle from 'root/client/hooks/use-toggle';

import Collapse from '../collapse/collapse';
import Text from '../text/text';

import { StepIndicator as Props } from './step-indicator.types';

const StepIndicator: React.FC<Props> = ({
  next,
  progress,
  progressPercentage,
  steps,
  title,
}) => {
  const [open, toggleOpen] = useToggle();

  return (
    <div className="step-indicator">
      <div className="step-indicator__trigger" onClick={toggleOpen}>
        <div className="step-indicator__text">
          <Text size={Text.sizes.large}>{title}</Text>
          {next ? <Text className="step-indicator__next">{next}</Text> : null}
        </div>
        <div className="step-indicator__progress">
          <Text>{progress}</Text>
          <ArrowDown />
        </div>
      </div>
      <div className="step-indicator__progress-bar">
        <div
          className="step-indicator__progress-bar__percentage"
          style={{ width: progressPercentage }}
        ></div>
      </div>
      <Collapse isOpen={open}>
        <div className="step-indicator__steps">
          {steps.map(step => (
            <a
              className={cn('step-indicator__step', {
                'step-indicator__step--faded': !step.completed && !step.active,
              })}
              key={step.href}
              href={step.href}
            >
              <div className="step-indicator__step-title">
                {step.completed ? (
                  <div className="step-indicator__step-completed">
                    <Booked />
                  </div>
                ) : (
                  <Text theme={step.active ? Text.themes.emphasis : undefined}>
                    {step.stepIndex}
                  </Text>
                )}
                <Text theme={step.active ? Text.themes.emphasis : undefined}>
                  {step.title}
                </Text>
              </div>
              {step.subTitle ? (
                <Text size={Text.sizes.small}>{step.subTitle}</Text>
              ) : null}
            </a>
          ))}
        </div>
      </Collapse>
    </div>
  );
};

export default StepIndicator;
