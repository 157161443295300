import AboutFreshPage_import from '/home/vsts/work/1/s/client/pages/about-fresh-page/about-fresh-page.tsx';
import AlternateCheckoutAboutYou_import from '/home/vsts/work/1/s/client/pages/alternate-checkout-about-you/alternate-checkout-about-you.tsx';
import AlternateCheckoutConfirmation_import from '/home/vsts/work/1/s/client/pages/alternate-checkout-confirmation/alternate-checkout-confirmation.tsx';
import AlternateCheckoutMembership_import from '/home/vsts/work/1/s/client/pages/alternate-checkout-membership/alternate-checkout-membership.tsx';
import AlternateCheckoutPayment_import from '/home/vsts/work/1/s/client/pages/alternate-checkout-payment/alternate-checkout-payment.tsx';
import AlternateCheckoutSelectClub_import from '/home/vsts/work/1/s/client/pages/alternate-checkout-select-club/alternate-checkout-select-club.tsx';
import ArticlePage_import from '/home/vsts/work/1/s/client/pages/article-page/article-page.tsx';
import ArticlesPage_import from '/home/vsts/work/1/s/client/pages/articles-page/articles-page.tsx';
import CheckoutConfirmationPage_import from '/home/vsts/work/1/s/client/pages/checkout-confirmation-page/checkout-confirmation-page.tsx';
import CheckoutPaymentErrorPage_import from '/home/vsts/work/1/s/client/pages/checkout-payment-error-page/checkout-payment-error-page.tsx';
import CheckoutPaymentPage_import from '/home/vsts/work/1/s/client/pages/checkout-payment-page/checkout-payment-page.tsx';
import CheckoutRegisterMemberPage_import from '/home/vsts/work/1/s/client/pages/checkout-register-member-page/checkout-register-member-page.tsx';
import CheckoutSelectClubPage_import from '/home/vsts/work/1/s/client/pages/checkout-select-club-page/checkout-select-club-page.tsx';
import CheckoutSelectMembershipPage_import from '/home/vsts/work/1/s/client/pages/checkout-select-membership-page/checkout-select-membership-page.tsx';
import CheckoutSelectProductPage_import from '/home/vsts/work/1/s/client/pages/checkout-select-product-page/checkout-select-product-page.tsx';
import ClubListPage_import from '/home/vsts/work/1/s/client/pages/club-list-page/club-list-page.tsx';
import ClubPage_import from '/home/vsts/work/1/s/client/pages/club-page/club-page.tsx';
import ClubsMapPage_import from '/home/vsts/work/1/s/client/pages/clubs-map-page/clubs-map-page.tsx';
import CustomerService_import from '/home/vsts/work/1/s/client/pages/customer-service/customer-service.tsx';
import CustomerServiceCategory_import from '/home/vsts/work/1/s/client/pages/customer-service-category/customer-service-category.tsx';
import DropInConfirmationPage_import from '/home/vsts/work/1/s/client/pages/drop-in-confirmation-page/drop-in-confirmation-page.tsx';
import DropInPaymentPage_import from '/home/vsts/work/1/s/client/pages/drop-in-payment-page/drop-in-payment-page.tsx';
import DropInRegisterMemberPage_import from '/home/vsts/work/1/s/client/pages/drop-in-register-member-page/drop-in-register-member-page.tsx';
import DropInSelectClubPage_import from '/home/vsts/work/1/s/client/pages/drop-in-select-club-page/drop-in-select-club-page.tsx';
import EmployeeLogInPage_import from '/home/vsts/work/1/s/client/pages/employee-log-in-page/employee-log-in-page.tsx';
import ErrorPage_import from '/home/vsts/work/1/s/client/pages/error-page/error-page.tsx';
import GroupExerciseTypePage_import from '/home/vsts/work/1/s/client/pages/group-exercise-type-page/group-exercise-type-page.tsx';
import GroupExerciseTypesPage_import from '/home/vsts/work/1/s/client/pages/group-exercise-types-page/group-exercise-types-page.tsx';
import LandingPage_import from '/home/vsts/work/1/s/client/pages/landing-page/landing-page.tsx';
import MemberCareFormPage_import from '/home/vsts/work/1/s/client/pages/member-care-form-page/member-care-form-page.tsx';
import PersonalTrainerPage_import from '/home/vsts/work/1/s/client/pages/personal-trainer-page/personal-trainer-page.tsx';
import PersonalTrainersPage_import from '/home/vsts/work/1/s/client/pages/personal-trainers-page/personal-trainers-page.tsx';
import PersonalTrainingPage_import from '/home/vsts/work/1/s/client/pages/personal-training-page/personal-training-page.tsx';
import RootPage_import from '/home/vsts/work/1/s/client/pages/root-page/root-page.tsx';
import WaitingArea_import from '/home/vsts/work/1/s/client/pages/waiting-area/waiting-area.tsx';
import WebAdminPage_import from '/home/vsts/work/1/s/client/pages/web-admin-page/web-admin-page.tsx';
import WorkoutOfferPage_import from '/home/vsts/work/1/s/client/pages/workout-offer-page/workout-offer-page.tsx';
export default {
AboutFreshPage: AboutFreshPage_import,
AlternateCheckoutAboutYou: AlternateCheckoutAboutYou_import,
AlternateCheckoutConfirmation: AlternateCheckoutConfirmation_import,
AlternateCheckoutMembership: AlternateCheckoutMembership_import,
AlternateCheckoutPayment: AlternateCheckoutPayment_import,
AlternateCheckoutSelectClub: AlternateCheckoutSelectClub_import,
ArticlePage: ArticlePage_import,
ArticlesPage: ArticlesPage_import,
CheckoutConfirmationPage: CheckoutConfirmationPage_import,
CheckoutPaymentErrorPage: CheckoutPaymentErrorPage_import,
CheckoutPaymentPage: CheckoutPaymentPage_import,
CheckoutRegisterMemberPage: CheckoutRegisterMemberPage_import,
CheckoutSelectClubPage: CheckoutSelectClubPage_import,
CheckoutSelectMembershipPage: CheckoutSelectMembershipPage_import,
CheckoutSelectProductPage: CheckoutSelectProductPage_import,
ClubListPage: ClubListPage_import,
ClubPage: ClubPage_import,
ClubsMapPage: ClubsMapPage_import,
CustomerService: CustomerService_import,
CustomerServiceCategory: CustomerServiceCategory_import,
DropInConfirmationPage: DropInConfirmationPage_import,
DropInPaymentPage: DropInPaymentPage_import,
DropInRegisterMemberPage: DropInRegisterMemberPage_import,
DropInSelectClubPage: DropInSelectClubPage_import,
EmployeeLogInPage: EmployeeLogInPage_import,
ErrorPage: ErrorPage_import,
GroupExerciseTypePage: GroupExerciseTypePage_import,
GroupExerciseTypesPage: GroupExerciseTypesPage_import,
LandingPage: LandingPage_import,
MemberCareFormPage: MemberCareFormPage_import,
PersonalTrainerPage: PersonalTrainerPage_import,
PersonalTrainersPage: PersonalTrainersPage_import,
PersonalTrainingPage: PersonalTrainingPage_import,
RootPage: RootPage_import,
WaitingArea: WaitingArea_import,
WebAdminPage: WebAdminPage_import,
WorkoutOfferPage: WorkoutOfferPage_import
};