import ArrowDown24 from '@sats-group/icons/24/arrow-down';
import cn from 'classnames';
import React from 'react';

import useToggle from 'root/client/hooks/use-toggle';

import Collapse from '../collapse/collapse';
import Text from '../text/text';

import { ExpanderListItem as Props } from './expander-list-item.types';

const ExpanderListItem: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ areChildrenPadded, children, title }) => {
  const [isOpen, toggle] = useToggle(false);

  return (
    <div className="expander-list-item">
      <button className="expander-list-item__trigger" onClick={toggle}>
        <Text
          size={Text.sizes.basic}
          theme={Text.themes.emphasis}
          elementName="h2"
        >
          {title}
        </Text>
        <div
          className={cn('expander-list-item__icon', {
            'expander-list-item__icon--flipped': isOpen,
          })}
        >
          <ArrowDown24 />
        </div>
      </button>
      <div
        className={cn('expander-list-item__drawer', {
          'expander-list-item__drawer--open': isOpen,
        })}
      >
        <Collapse isOpen={isOpen}>
          <div
            className={cn('expander-list-item__children', {
              'expander-list-item__children--padded': areChildrenPadded,
            })}
          >
            {children}
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default ExpanderListItem;
