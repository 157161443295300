/* eslint-disable react/no-multi-comp */
import ArrowDown from '@sats-group/icons/24/arrow-down';
import cn from 'classnames';
import Collapse from 'react-tiny-collapse';
import React, { useState } from 'react';

import Link from '../link/link';
import LinkButton from '../link-button/link-button';
import Text from '../text/text';

import { type Expander as Props, themes } from './expander.types';

const ExpanderListItem: React.FC<
  React.PropsWithChildren<Props<unknown>['items'][number]['listItemProps']>
> = ({
  actions,
  defaultOpen = false,
  faq,
  icon,
  id,
  links,
  children,
  metadata,
  title,
  value,
}) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div
      className="expander__list-item"
      key={id}
      itemScope={faq ? faq : undefined}
      itemProp={faq ? 'mainEntity' : undefined}
      itemType={faq ? 'https://schema.org/Question' : undefined}
    >
      <button
        aria-controls={id}
        aria-expanded={isOpen}
        className={cn('expander__trigger', {
          'expander__trigger--triggered': isOpen,
          'expander__trigger--w-value': value,
        })}
        onClick={toggle}
      >
        {icon ? <div className="expander__icon">{icon}</div> : null}
        <div className="expander__trigger-title">
          <Text elementName="h3" tight>
            {title}
          </Text>
        </div>
        <div
          className={cn('expander__icon', {
            'expander__icon--flipped': isOpen,
          })}
        >
          <ArrowDown />
        </div>
      </button>
      <div
        className={cn('expander__drawer', {
          'expander__drawer--open': isOpen,
        })}
        id={id}
      >
        <Collapse isOpen={isOpen}>
          <div
            className="expander__content"
            itemScope={faq ? faq : undefined}
            itemProp={faq ? 'acceptedAnswer' : undefined}
            itemType={faq ? 'https://schema.org/Answer' : undefined}
          >
            <div
              className="expander__children"
              itemProp={faq ? 'text' : undefined}
            >
              {children}
            </div>
            {actions.length ? (
              <div className="expander__actions">
                {actions.map(action => (
                  <div className="expander__action" key={action.href}>
                    <LinkButton {...action} />
                  </div>
                ))}
              </div>
            ) : null}
            {metadata || (links && links.length) ? (
              <div className="expander__metadata">
                <hr className="expander__divider" />
                <div className="expander__meta-content">
                  {metadata ? (
                    <div className="expander__meta-text">
                      <Text elementName="span" size={Text.sizes.small}>
                        {metadata}
                      </Text>
                    </div>
                  ) : null}
                  {links && links.length ? (
                    <div className="expander__links">
                      {links.map(link => (
                        <div className="expander__link" key={link.href}>
                          <Link {...link} />
                        </div>
                      ))}
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null}
          </div>
        </Collapse>
      </div>
    </div>
  );
};

const Expander = <T extends {}>({
  description,
  faq,
  itemRenderer,
  items,
  theme,
  title,
}: Props<T> & { itemRenderer: React.FC<T> }) => (
  <div className="expander">
    {title || description ? (
      <div className="expander__header">
        {title ? (
          <Text
            elementName="h2"
            size={Text.sizes.header2}
            theme={Text.themes.emphasis}
            tight
          >
            {title}
          </Text>
        ) : null}
        {description ? (
          <Text size={Text.sizes.basic}>{description}</Text>
        ) : null}
      </div>
    ) : null}
    <div
      className={cn('expander__list', { [`expander__list--${theme}`]: theme })}
    >
      {items.map(item => (
        <ExpanderListItem
          key={item.listItemProps.id}
          {...item.listItemProps}
          faq={faq}
        >
          {React.createElement(itemRenderer, item.props)}
        </ExpanderListItem>
      ))}
    </div>
  </div>
);

Expander.themes = themes;

export default Expander;
