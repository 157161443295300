import React from 'react';

import ClubListItem from 'root/client/components/club-list-item/club-list-item';

import ExpanderListItem from '../expander-list-item/expander-list-item';

import { ExpandableClubs as Props } from './expandable-clubs.types';

const ExpandableClubs: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ clusters }) => (
  <>
    <div className="expandable-clubs">
      {clusters.map(cluster => (
        <ExpanderListItem title={cluster.name} key={cluster.name}>
          <ul className="expandable-clubs__content">
            {cluster.clubs?.map(club => (
              <li key={club.name} className="expandable-clubs__list-item">
                <ClubListItem {...club} />
              </li>
            ))}
          </ul>
        </ExpanderListItem>
      ))}
    </div>
  </>
);

export default ExpandableClubs;
