import cn from 'classnames';
import React from 'react';

import Price from '../price/price';
import RadioButton from '../radio-button/radio-button';
import Text from '../text/text';

import { ChoiceBox as Props } from './choice-box.types';

const ChoiceBox: React.FunctionComponent<Props> = ({
  description,
  icon,
  onClick = () => {},
  price,
  radio,
  selected,
  title,
}) => {
  return (
    <div
      className={cn('choice-box', {
        'choice-box--selected': selected,
      })}
    >
      <div className="choice-box__content" onClick={() => onClick()}>
        <div>
          <div className="choice-box__header">
            {icon ? icon : null}
            <Text
              className={cn('choice-box__title', {
                'choice-box__title--selected': selected,
              })}
              theme={Text.themes.emphasis}
              size={Text.sizes.large}
            >
              {title}
            </Text>
            <RadioButton
              {...radio}
              visibleLabel={false}
              defaultChecked={selected}
            />
          </div>
          <Text>{description}</Text>
        </div>
        {price ? (
          <div className="choice-box__price">
            <Price
              {...price}
              theme={selected ? Price.themes.featured : undefined}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ChoiceBox;
