import React from 'react';

import ContentContainer from '../content-container/content-container';
import Logo from '../logo/logo';
import StepIndicator from '../step-indicator/step-indicator';
import Text from '../text/text';

import { SalesLayout as Props } from './sales-layout.types';

const SalesLayout: React.FC<React.PropsWithChildren<Props>> = ({
  logo,
  title,
  stepIndicator,
  children,
}) => (
  <div className="sales-layout">
    <div className="sales-layout__header">
      <div className="sales-layout__logo">
        <Logo {...logo} />
      </div>
    </div>
    <div className="sales-layout__body">
      <Text
        className="sales-layout__title"
        theme={Text.themes.emphasis}
        size={Text.sizes.header2}
        variant={Text.variants.hero}
      >
        {title}
      </Text>
      <div className="sales-layout__step-indicator">
        <ContentContainer
          width={ContentContainer.widths.narrow}
        ></ContentContainer>
        <StepIndicator {...stepIndicator} />
      </div>
      <div className="sales-layout__content">{children}</div>
    </div>
  </div>
);

export default SalesLayout;
