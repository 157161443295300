import cn from 'classnames';
import React from 'react';

import Text from '../text/text';

import { Price as Props, themes } from './price.types';

const Price: React.FC<Props> & { themes: typeof themes } = ({
  pre,
  post,
  theme,
  value,
}) => (
  <div className={cn('price', { [`price--${theme}`]: theme })}>
    {pre ? <Text theme={Text.themes.emphasis}>{pre}</Text> : null}
    <Text variant={Text.variants.hero} size={Text.sizes.header3}>
      {value}
    </Text>
    <Text theme={Text.themes.emphasis}>{post}</Text>
  </div>
);

Price.themes = themes;

export default Price;
